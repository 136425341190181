@import url("https://fonts.googleapis.com/css2?family=MuseoModerno:wght@800&display=swap");

.admin_layout_content {
  max-height: calc(100vh - 90px);
  overflow: auto;
}

.ant-layout-sider {
  background: #05253a !important;
}

.ant-menu {
  background: #05253a !important;
  overflow: auto;
  max-height: calc(100vh - 85px);
}

.ant-menu-item-selected {
  background-color: #5ea4b4 !important;
}

.ant-menu-item-group-title {
  color: #fff !important;
}

.user_header {
  color: #fff;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user_header .anticon {
  font-size: 20px;
  margin-right: 8px;
}

.logout_header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logout_header button {
  color: #fff;
}

.logout_header button:hover {
  opacity: 0.7;
  color: #fff !important;
}

.logout_header .anticon {
  font-size: 20px;
}

.ant-menu-item-group {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: 10px 0;
}

.ant-menu-item-group-title {
  font-weight: 600;
}
