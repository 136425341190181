.ant-btn svg {
  margin: 0 !important;
}

.delete-action {
  margin-left: 5px;
}
.welcome_letter {
  width: 100%;
}

.welcome-letter-card {
  padding: 30px;
}

.welcome-letter-card .ql-editor {
  height: 300px !important;
}

.welcome_letter_tabs .ant-tabs-nav-wrap {
  width: 100%;
}

.welcome_letter_tabs .ant-tabs-nav-list {
  width: 100%;
}

.welcome_letter_tabs .ant-tabs-tab {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-segmented-group,
.ant-segmented {
  width: 100%;
}

.ant-segmented-item {
  width: 50%;
  min-height: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-segmented-item-label {
  display: flex;
  justify-content: center;
  align-items: center;
}
