body {
  padding-bottom: 0 !important;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
}

.layout-signin {
  height: 100%;
}

.layout-signin .signin {
  padding-top: 0 !important;
}

.layout-signin .ant-row {
  margin: 0 !important;
  height: 100%;
}

.layout-signin .ant-col {
  padding: 0 !important;
}

.layout-signin .form-col {
  padding: 30px !important;
  max-width: 500px !important;
  margin: auto;
}

.layout-signin .sigin-div-image {
  background-image: url("../../assets/images/Encontro-Renal-Splash-Screen.jpg");
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
}

.layout-signin .sigin-div-image .logo {
  max-width: 200px;
}

.layout-signin .sigin-div-image .app {
  margin-top: 40px;
  max-width: 250px;
}
