
.ant-btn svg {
    margin: 0 !important;
}

.delete-action {
    margin-left: 5px;
}

.img-preview {
    height: 60px;
    width: 60px;
    background-position: center;
    background-size: cover;
    border-radius: 30px;

}

.img-preview.no-img svg {
    width: 100%;
    height: 100%;
    color: rgb(0, 21, 41);
}

.title-speaker {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.subtitle-speaker {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.modal-speaker .left-div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal-antd-speaker {
    width: 600px !important;
}

.ant-picker {
    height: 40px;
    border-radius: 6px !important;
}