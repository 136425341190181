body {
  margin: 0;
}

.full_container {
  max-width: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container {
  max-width: 1400px;
  width: 100%;
  margin: auto;
  padding: 20px;
}

.white {
  color: #FFF !important;
}

.site_content {
  min-height: calc(100vh - 70px);
}

.submit_button {
  background: #061848;
  border: 1px solid #061848;
  color: #FFF;
}

.submit_button:hover {
  background: #fff;
  border: 1px solid #061848;
  color: #061848;
}

.root,
.App,
.ant-layout {
  min-height: 100vh;
}

.App.admin {
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

.ql-container {
  height: 100% !important;
  max-height: 100px;
  min-height: 100px;
}


.welcome_letter .ql-container {
  height: 100% !important;
  max-height: 350px;
  min-height: 350px;
}

.ql-editor {
  height: 100% !important;
  max-height: 100px;
  min-height: 100px;
}

.welcome_letter .ql-editor {
  height: 100% !important;
  max-height: 350px;
  min-height: 350px;
}

.title_react_quill .ql-editor {
  height: 100% !important;
  max-height: 100px;
  min-height: 100px;
}

.title_react_quill  .ql-container {
  height: 100% !important;
  max-height: 100px;
  min-height: 100px;
}

.hidden {
  display: none !important;
}

.ant-layout-content {
  padding: 40px;
}

.ant-layout-content.signin {
  padding: 0 !important;
  background-color: #f8f8f9;
}

.actions-buttons-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ant-input-number {
  width: 100%;
}



.preview_file  {
  display: none;
}

.preview_file.hasPreview  {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  border-radius: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
}

.preview_file.sponsor.hasPreview  {
  background-size: contain;
}

.preview_file img {
  width: auto;
  height: 90%;
}

.dragger.hasPreview .ant-upload .ant-upload-btn .ant-upload-drag-container p {
  opacity: 0;
}

.admin_layout_content {
  background-color: #f8f8f9;
}

.dashboard_page {
  min-height: 100%;
  background: #FFF;
  border-radius: 10px;
}

.dashboard_title_row {
  padding: 20px 20px 0 20px !important;
}

.dashboard_content_row {
  padding: 20px !important;
  overflow: auto;
  min-height: calc(100vh - 290px);
}

.ant-avatar {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.color-empty {
  color: rgba(0, 0, 0, 0.25)
}

.empty_data svg {
  font-size: 100px;
  color: rgba(0, 0, 0, 0.25)
}

.admin_layout_content::-webkit-scrollbar {
  width: 1em;
}
 
.admin_layout_content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}
 
.admin_layout_content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  outline: 0px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.table_item {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFF;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 21, 41, 0.05);
    -moz-box-shadow: 0px 0px 10px 2px rgba(0, 21, 41, 0.05);
    box-shadow: 0px 0px 10px 0px rgba(0, 21, 41, 0.05);
    border: 2px solid rgba(0, 21, 41, 0.05)
}

/* ------------------------------------- */
/* ------------------------------------- */
/* FONTS */
/* ------------------------------------- */
/* ------------------------------------- */

.f-20 {
  font-size: 20px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-18 {
  font-size: 18px !important;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: bold !important;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* WIDTH */
/* ------------------------------------- */
/* ------------------------------------- */

.w-100 {
  width: 100%;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* HEIGHT */
/* ------------------------------------- */
/* ------------------------------------- */

.h-100 {
  height: 100%;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* FLEX */
/* ------------------------------------- */
/* ------------------------------------- */

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

/* ----------- */
/* JUSTIFY-CONTENT */
/* ----------- */

.jc-center {
  justify-content: center !important;
}

.jc-start {
  justify-content: flex-start !important;
}

.jc-end {
  justify-content: flex-end !important;
}

.jc-sb {
  justify-content: space-between !important;
}

.jc-sa {
  justify-content: space-around !important;
}

/* ----------- */
/* ALIGN-ITEMS */
/* ----------- */

.ai-center {
  align-items: center !important;
}

.ai-start {
  align-items: flex-start !important;
}

.ai-end {
  align-items: flex-end !important;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* PADDINGS */
/* ------------------------------------- */
/* ------------------------------------- */

.p-10 {
  padding: 10px !important;
}

.p-20 {
  padding: 20px !important;
}

/* ----------- */
/* PADDING-BOTTOM */
/* ----------- */
.pb-0 {
  padding-bottom: 0 !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

/* ----------- */
/* PADDING-LEFT */
/* ----------- */

.pl-0 {
  padding-left: 0 !important;
}

/* ----------- */
/* PADDING-RIGHT */
/* ----------- */

.pr-0 {
  padding-right: 0 !important;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* MARGINS */
/* ------------------------------------- */
/* ------------------------------------- */

.m-0 {
  margin: 0 !important;
}

.m-10 {
  margin: 10px !important;
}

/* ----------- */
/* MARGIN-TOP */
/* ----------- */

.mt-0 {
  margin-top: 0 !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

/* ----------- */
/* MARGIN-BOTTOM */
/* ----------- */

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

/* ----------- */
/* MARGIN-LEFT */
/* ----------- */

.ml-auto {
  margin-left: auto !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

/* ----------- */
/* MARGIN-RIGHT */
/* ----------- */

.mr-auto {
  margin-right: auto !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* TEXT */
/* ------------------------------------- */
/* ------------------------------------- */

h1 {
  font-size: 40px !important;
  color: #061848 !important;
  font-weight: bold !important;
}

h2 {
  font-size: 30px !important;
  color: #061848 !important;
  font-weight: bold !important;
}

h3 {
  font-size: 24px;
  color: #061848 !important;
  font-weight: bold !important;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* ANTD */
/* ------------------------------------- */
/* ------------------------------------- */

.ant-layout {
  background: #FFF;
}

.ant-layout-header {
  height: 85px;
}


/* ------------------------------------- */
/* ------------------------------------- */
/* MEDIA QUERIES */
/* ------------------------------------- */
/* ------------------------------------- */
.content_header .menu_right_side .mobile {
  display: none;
}

.content_header .menu_right_side .desktop {
  display: flex !important;
}

@media only screen and (max-width: 1200px) {
  .content_header .menu_right_side .mobile {
    display: flex !important;
  }

  .content_header .menu_right_side .desktop {
    display: none !important;
  }
}