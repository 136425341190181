.loading_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: rgb(0, 21, 41);
}

.loading_div .ant-spin {
  font-size: 30px;
}

.loading_div .ant-spin .ant-spin-dot-item {
  background-color: #fff;
}

.loading_div .logo {
  color: #fff;
  margin: 20px;
  font-size: 50px;
}
